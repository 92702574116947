import * as React from 'react';
import s from './Loading.scss';

export class Loading extends React.PureComponent {
  public render() {
    return (
      <>
        <span className={s.dot}>.</span>
        <span className={s.dot}>.</span>
        <span className={s.dot}>.</span>
      </>
    );
  }
}
