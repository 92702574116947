import * as React from 'react';
import InputRange from './InputRange/js';
import type {InputRangeProps} from './InputRange/react-input-range';
import styles from './PriceSlider.scss';
import classNames from 'classnames';

export type IPriceSliderProps = Omit<InputRangeProps, 'classNames'> & {isCategoryPage: boolean};

export class PriceSlider extends React.PureComponent<IPriceSliderProps> {
  public render(): React.ReactNode {
    const {isCategoryPage, ...inputRangeProps} = this.props;
    return (
      <div className={styles.sliderWrapper}>
        <InputRange
          {...inputRangeProps}
          classNames={{
            inputRange: classNames(styles.priceSlider, {[styles.isCategoryPage]: isCategoryPage}),
            slider: styles.slider,
            valueLabel: classNames(styles.valueLabel, styles.label),
            track: styles.track,
            activeTrack: classNames(styles.track, styles.activeTrack),
            minLabel: classNames(styles.minLabel, styles.label, {[styles.isCategoryPage]: isCategoryPage}),
            maxLabel: classNames(styles.maxLabel, styles.label, {[styles.isCategoryPage]: isCategoryPage}),
            disabledInputRange: styles.isDisabled,
            labelContainer: styles.labelContainer,
            sliderContainer: styles.sliderContainer,
          }}
        />
      </div>
    );
  }
}
