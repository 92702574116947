import {Filters} from '../../../gallery/components/Filters/Filters';
import React from 'react';
import {IGlobals} from '../../../gallery/galleryGlobalStrategy';
import {DataHook} from '../../../category/components/CategoriesApp/CategoriesApp';
import classNames from 'classnames';
import s from './SideFilters.scss';

export const SideFilters = ({
  clearFilters,
  shouldShowClearFilters,
  shouldStretchVertically = true,
}: IGlobals & {shouldStretchVertically?: boolean}) => {
  const className = classNames(s.filters, {
    [s.stretchVertically]: shouldStretchVertically,
  });

  return (
    <aside className={className} data-hook={DataHook.SideFilters}>
      <Filters shouldShowClearFiltersButton={shouldShowClearFilters} clearFilters={clearFilters} />
    </aside>
  );
};
