/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import {CheckboxList} from '../CheckboxList/CheckboxList';
import * as _ from 'lodash';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../galleryGlobalStrategy';
import {CheckboxGroup} from 'wix-ui-tpa';
import {Checkbox} from 'wix-ui-tpa/cssVars';
import {classes, st} from './MultipleSelectionFilter.st.css';
import classNames from 'classnames';

export interface IMultipleSelectionFilterProps extends IGalleryGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string[];
}

class MultipleSelectionFilterComp extends React.Component<IMultipleSelectionFilterProps> {
  private handleChange(e) {
    const selectedOptionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedOptionId);
  }

  public shouldComponentUpdate(nextProps: IMultipleSelectionFilterProps) {
    return !_.isEqual(this.props, nextProps);
  }

  public render() {
    const {
      options,
      selected,
      filterId,
      globals: {compId, isCategoryPage, filterProducts, shouldShowMobile},
    } = this.props;

    if (isCategoryPage) {
      return (
        <CheckboxGroup>
          {options.map((option, index) => {
            return (
              <Checkbox
                className={classNames(st(classes.root, {useColorSetting: !shouldShowMobile}))}
                data-hook={`checkbox-${index}`}
                key={option.id}
                name={option.id}
                label={option.name}
                checked={selected.includes(option.id)}
                onChange={() => filterProducts(filterId, option.id)}
              />
            );
          })}
          ;
        </CheckboxGroup>
      );
    }

    return (
      <CheckboxList
        id={`${filterId}${compId}`}
        selected={selected}
        options={options}
        onChange={(e) => this.handleChange(e)}
        isCategoryPage={this.props.globals.isCategoryPage}
      />
    );
  }
}

export const MultipleSelectionFilter = withGlobals(MultipleSelectionFilterComp);
