import {getWrappedComponent} from '../../../appConfig';
import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {CategoriesApp} from '../../../category/components/CategoriesApp/CategoriesApp';
import stylesParams from '../stylesParams';

export default getWrappedComponent({
  Component: CategoriesApp,
  stylesParams,
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
