export function hasScrollReachedElementBottom(
  elementRect: DOMRect,
  bodyRect: DOMRect,
  currentScrollTop: number,
  windowHeight: number
): boolean {
  const elementTop = elementRect.top - bodyRect.top;
  const maxScroll = elementRect.height + elementTop;
  const currScrollBottom = currentScrollTop + windowHeight;
  const bottomElementScrollRatio = currScrollBottom / maxScroll;

  return bottomElementScrollRatio >= 1;
}
